import Vue from "vue";
import Vuex from "vuex";
import consult from "@/store/consult";
import currentInsertion from "@/store/currentInsertion";
import currentLocation from "@/store/currentLocation";
import currentPatient from "@/store/currentPatient";
import navigation from "@/store/navigation";
import org from "@/store/org";
import subscriptionPlan from "@/store/subscriptionPlan";
import subscriptionFeature from "@/store/subscriptionFeature";
import vendorProduct from "@/store/vendor/product";
import vendorProducts from "@/store/vendor/products";
import user from "@/store/user";
import vendor from "@/store/vendor";
import loyalty from "@/store/loyalty";
import directoryProfiles from "@/store/directoryProfiles";
import admin from "@/store/simpatraAdmin";

// MEDCENTER
import patientChart from "@/store/med-center/orders/patientChart";

import marketplace from "@/store/marketplace";
// new marketplace modules
import marketplaceSearch from "@/store/marketplace/search";
import marketplaceProduct from "@/store/marketplace/product";
import marketplaceVendor from "@/store/marketplace/vendor";
import marketplaceCategory from "@/store/marketplace/category";
import marketplaceCarousel from "@/store/marketplace/carousel";

// new org modules
import orgReceipts from "@/store/org/receipts";
import orgSubscription from "@/store/org/subscription";
import orgAnalytics from "@/store/org/analytics";
import orgLeads from "@/store/org/leads";

// new location modules
import locationReceipts from "@/store/location/receipts";
import locationRegistrations from "@/store/location/registrations";

import step from "@/store/onboarding/step";
import sellerStore from "@/store/sellerStore";
import landingPage from "@/store/landingPage";
import medCenter from "@/store/medCenter";

import labOrders from "@/store/med-center/labOrders";
import globalStore from "@/store/globalStore";

// Vendor
import vendorAccountsModule from "@/store/vendorModule/vendorAccountsModule";
import vendorOrdersModule from "@/store/vendorModule/vendorOrdersModule";
import vendorProductsModule from "@/store/vendorModule/vendorProductsModule";
import vendorTierModule from "@/store/vendorModule/vendorTierModule";

import usersModule from "@/store/usersModule";

import orders from "@/store/orders";
import ordersModule from "@/store/ordersModule";
import locationsModule from "@/store/locationsModule";

import orgModule from "@/store/orgModule";
import filesModule from "@/store/filesModule";

// Marketplace
import orderHistoryModule from "@/store/marketplaceModule/orderHistoryModule";

// Intellipel
import consultsModule from "@/store/consultsModule";

// Practice Builder
import patientFormsModule from "@/store/practiceBuilderModule/patientFormsModule";

import labResultModule from "@/store/labResultModule";
import accountsModule from "@/store/accountsModule";
import patientModule from "@/store/patientModule";
import jPatientModule from "@/store/jpatientModule";
import productsModule from "@/store/productsModule";
import subscriptionModule from "@/store/subscriptionModule";
import paymentModule from "@/store/paymentModule";
import directoryModule from "@/store/directoryModule";
import dosingModule from "@/store/dosingModule";
import shippingModule from "@/store/shippingModule";
import lookupModule from "@/store/lookupModule";
import onboardingModule from "@/store/onboardingModule";
import publicAPIModule from "@/store/publicAPIModule";

Vue.use(Vuex);

export const createStore = () => {
  return new Vuex.Store({
    modules: {
      consult,
      currentInsertion,
      currentLocation,
      currentPatient,
      navigation,
      org,
      admin,
      subscriptionPlan,
      subscriptionFeature,

      // VENDOR
      vendor,
      vendorProduct,
      vendorProducts,
      orders,
      ordersModule,
      vendorAccountsModule,
      vendorOrdersModule,
      vendorProductsModule,
      vendorTierModule,

      // Marketplace
      orderHistoryModule,

      // USER
      user,
      loyalty,
      orgReceipts,
      orgSubscription,
      orgAnalytics,
      orgLeads,
      directoryProfiles,
      marketplace,

      // new marketplace modules
      marketplaceSearch,
      marketplaceProduct,
      marketplaceVendor,
      marketplaceCategory,
      marketplaceCarousel,
      // new location modules
      locationRegistrations,
      locationReceipts,
      step,
      sellerStore,
      landingPage,
      medCenter,
      labOrders,

      orgModule,
      filesModule,

      // MEDCENTER MODULE
      patientChart,
      globalStore,

      // LOCATION MODULES
      locationsModule,

      // USER MODULE
      usersModule,

      // INTELLIPEL MODULE
      consultsModule,

      // OTHERS
      patientFormsModule,
      labResultModule,
      accountsModule,
      patientModule,
      jPatientModule,
      productsModule,
      subscriptionModule,
      paymentModule,
      directoryModule,
      dosingModule,
      shippingModule,
      lookupModule,
      onboardingModule,
      publicAPIModule,
    },
  });
};

export default createStore();
